.borderRequired{
    border:none;
}
.borderRequiredTrue{
    border:1px solid var(--mainColor) !important;
}
.titleError{
    display: none;
}
.titleErrorTrue{
    padding-left: 20px;
    padding-top: 5px;
    font-size: var(--smallText);
    color: var(--mainColor);
    font-weight: bold;
}

.Datebtn{
    padding: 12px 15px;
    height: max-content;
    width: fit-content;
    background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    cursor:pointer;
}

.addArticle{
    background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    color:white;
    width: fit-content;
    font-weight: bold;
    padding: 12px 15px;
    margin-top: 15px;
    margin-bottom: 22px;
    border-radius: 4px; 
    border: none;
    cursor: pointer;
}

.tagandimagediv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom:20px;
    align-items: baseline;
}

.addTitleInner{
    padding: 10px 5px 10px 20px;
    border-radius: 4px;
    border-width: 1px;
    width: 98%;
    border-style: solid;
    border-color: #ddd; 
    font-size: var(--largeText);
    width: -webkit-fill-available;
    max-width: 98%;
}

.addTitleInner:focus-visible{
    outline: none;
}

.addDate{
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 4px;
    border-bottom: solid #ddd 1px;
}
.tagContainer{
    color: var(--mainColor);
    padding: 7px 10px 10px 0px;
    font-weight: bold;
}
.addFileStyle{
    border: 1px solid var(--mainColor);
}
.addFile{
    padding-top: 20px;
}

::-webkit-file-upload-button {
    background: var(--mainColor);
    color: white;
    padding: 12px 25px;
    font-size: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border: none;
    transition: all 0.15s ease;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;

}

.tagContainer > select{
    background: var(--fontBlack);
    padding: 10px 10px;
    border: none;
    color: #Fff;
    border-radius: 10px;
}

::file-selector-button{
    /* background: var(--mainColor); */
    background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    color: white;
    padding: 12px 20px;
    font-size: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border: none;
    transition: all 0.15s ease;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    
}
.errorMessage{
    color: var(--mainColor);
    font-weight: bold;
}